import React from 'react';
import { graphql } from 'gatsby';
import Img from '@components/Img';
import LayoutTabs from '../../layout/tabs';
import Sponsors from '../../components/Sponsors';
import MLink from '../../components/MLink';
import ContentCards from '../../components/ContentCards';
import HomeStatement from '../../components/HomeStatement';
import './dive.scss';

const ExperiencedPage = props => {
  const {
    data: {
      dataYaml: { homepage: data },
    },
    location,
  } = props;

  return (
    <LayoutTabs pathname={location.pathname} route="dive" title="Dive" activeTab="experienced" className="dive-page">
      <div className="dive-page-content">
        <div className="dive-page-content-header">
          <h2 className="dive-page-content-header-title">
            Create, reproduce, repeat and share efficiently!
          </h2>

          <p className="dive-page-content-header-text">
            Create and publish AI Modules within CI/CD-based workflows. Share your work
            to create a re-usable ML knowledgebase for fast prototyping. Use prepared
            environments without any additional work and coordinate your ML
            activities with your team. Don´t loose track of progress through
            end-to-end reproducibility.
          </p>
        </div>

        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Streamlined collaboration and control
            </h3>
            <p>
              Use git version control to track and manage your code. A simple to use publishing process 
              converts your code into ready-to-run modules. This way, you will not lose track
              of your progress and distributed workflows will become a reality in AI/ML.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Source code repository to track any changes
              </li>
              <li className="">
                CI/CD based pipelines and workflows
              </li>
              <li className="">
                Automated containerization of scripts
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_publishing.png"
              alt="mlreef | Streamlined collaboration and control"
            />
          </div>
        </div>

        <div className="featured-list reverse">
          <div className="featured-list-content">
            <h3>
              A holistic and flexible ML development environment
            </h3>
            <p>
              Enjoy a holistic approach as you manage your data, run jobs and view
              execution logs, create and compare experiments while assessing performance
              on used resources. MLReef allows you to seamlessly track each step of your
              development.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Store and manage your data in one place
              </li>
              <li className="">
                Track experiments and store model artifacts
              </li>
              <li className="">
                Highly interactive and easy data pipelining
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_experiments.png"
              alt="mlreef | A complete platform for AI/ML development"
            />
          </div>
        </div>

        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Fully customizable environments
            </h3>
            <p>
              Use a variety of customizable CPU & GPU based environments. Run them
              elastically on your infrastructure or directly on MLReef. Publishing
              your ML modules builds and stores the environment for future use.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Run unlimited jobs in parallel
              </li>
              <li className="">
                Fully scalable and elastic computing environment
              </li>
              <li className="">
                Forget about DevOps setup & maintenance
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_base_environments.png"
              alt="mlreef | Fully customizable execution environments"
            />
          </div>
        </div>
      </div>

      <Sponsors sponsors={data.sponsors} />

      <section className="banner-wide">
        <div className="banner-wide-title">
          You can make ML accessible by sharing your work!
        </div>
        <p className="banner-wide-text">
          Collaborate and share your work to create a nurturing ML experience for your team and the entire company.
        </p>
        <MLink href="https://mlreef.com/book-a-demo" className="btn btn-basic-primary mt-2">
          Talk to us!
        </MLink>
      </section>

      <section className="home-page-section examples">
        <HomeStatement
          title="Your Machine Learning development in one platform"
          text="Instead of disconnected toolchains, use MLReef to run your pipelines, process your data, version and track your experiments and models, manage teams and members, ... and much more.."
        />
      </section>

      <ContentCards className="pb-4" />

      <div className="banner-short dark card mt-4 mb-5">
        <div className="banner-short-container card-container banner pt-2 pb-4">
          <div className="m-auto">
            <p>
              Share and empower others, while you can focus on what you do best!
            </p>
            <a
              href="https://www.mlreef.com/book-a-demo"
              className="btn btn-outline-primary mx-auto mt-3"
              target="_blank"
              rel="noreferrer noopener"
            >
              Book your demo
            </a>
          </div>
        </div>
      </div>
    </LayoutTabs>
  );
};

export const query = graphql`
  query ExperiencedPageData {
    dataYaml {
      homepage {
        sponsors {
          logo
          title
          link
        }
      }
    }
  }
 `;

export default ExperiencedPage;
